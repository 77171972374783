import React from 'react';
import { SimpleHeader } from './simple-header';
import { SingleControlHeader } from './single-control-header';

import './section-header.scss';

interface IProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  firstControl?: React.ReactNode;
  secondControl?: React.ReactNode;
  thirdControl?: React.ReactNode;
  customClass?: string;
  customID?: string;
}

export const SectionHeader: React.FC<IProps> = ({
  title,
  subtitle,
  firstControl,
  secondControl,
  thirdControl,
  customClass,
  customID
}) => {
  return (
    <>
      {!firstControl && !secondControl && !thirdControl && (
        <SimpleHeader
          title={title}
          subtitle={subtitle}
          customClass={customClass}
          customID={customID}
        />
      )}
      {firstControl && !secondControl && !thirdControl && (
        <SingleControlHeader
          title={title}
          subtitle={subtitle}
          firstControl={firstControl}
          customClass={customClass}
        />
      )}
    </>
  );
};
