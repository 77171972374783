import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Card, Table } from 'react-bootstrap';
import { TOGCharacter } from '../../../modules/tog/common/components/tog-character';

const TOGGuidesEarlyPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="tog">
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/tog/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Early team progression</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/tog/categories/category_team.png"
            alt="Early team progression"
          />
        </div>
        <div className="page-details">
          <h1>Early team progression</h1>
          <h2>A guide to help you build your early-game team!</h2>
          <p>
            Last updated: <strong>18/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Importance of colors" />
        <p>
          In Tower of God: New World each character has a Color assigned to
          them, either <strong>Blue, Red, Green, Yellow or Purple</strong>. And
          like in any RPG game, the game uses the Elemental Wheel system to tie
          the Colors together. But what makes this game stand out is the penalty
          for ignoring (or the reward for abusing it): if you counter the enemy
          Color, you will do{' '}
          <strong>
            100% more damage to them and take 50% reduced damage from them
          </strong>
          .
        </p>
        <p>
          Still, while the three base Colors (Blue, Red, Green) always counter
          each other, you can manipulate the Yellow and Purple ones to negate
          the penalty. The image below shows the details of the system:
        </p>
        <StaticImage
          src="../../../images/tog/guides/elements.jpg"
          alt="Image"
        />
        <p>
          Because of this, Yellow/Purple teams are stronger for progression than
          Red/Blue/Green. For Yellow/Purple characters you can cancel out the
          penalty by simply having more characters of that color on your team
          than enemy does of the opposite color.{' '}
          <strong>
            So if you have 4 Yellow and enemy has 3 Purple, they won't deal
            additional damage to you, but you will still deal 100% more damage
            to them.
          </strong>
        </p>
        <p>
          Still, keep in mind that eventually you will want to build teams of
          each color - the Boss-slaying content in the game is pretty important
          and they also have Colors, so using characters that counter them will
          drastically increase the damage you do (and by that, the rewards you
          can obtain). But in the first few weeks, you mainly want to progress
          through the Floors of the main adventure and unlock all the features
          available in the game and for that, Yellow/Purple teams are best.
        </p>
        <SectionHeader title="Choosing characters" />
        <p>
          Now that we have the theory out of the way, let's talk about which
          characters from the Yellow/Purple factions stand out and will help you
          progress through the story faster.
        </p>
        <h5>Yellow faction</h5>
        <p>First let's go over Yellow characters:</p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Character</th>
              <th>Role</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="fast-ship-evan"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>Support</td>
              <td>
                We already explained how strong Evan is in other guides, but to
                summarize him - there isn't a Support/Healer in the game that
                can do what he does and he is the core of a lot of teams, no
                matter the color.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="flame-user-yihwa-yeon"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>DPS</td>
              <td>
                <strong>Yihwa</strong> is a the best Carry in the game as she
                deals massive damage to the whole battlefield with her Special
                Move. Basically, you just have to keep her alive till she uses
                it and in most cases it's enough to wipe the enemy team.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="black-march-bam"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>Support</td>
              <td>
                The main character who specializes in buffing his allies and
                controlling the battlefield. He will buff the highest ATK
                character on your team, making them deal more damage, survive
                for longer and also restoring their energy, so they can use the
                Special Move faster.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="hatz-donghae"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>Stall tank</td>
              <td>
                Grab a few copies of Hatz (the tank variant) as he's the best
                choice in the Yellow team for the stall spot.
              </td>
            </tr>
          </tbody>
        </Table>
        <h5>Purple faction</h5>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Character</th>
              <th>Role</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="steel-armored-karaka"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>Tank</td>
              <td>
                SSR+ tank who is a RNG machine. He also has cheat death mechanic
                and he can kill the enemy team by himself with his Special Move,
                but a lot needs to happen to work like that. If you have the
                time to restart the battle all over again, eventually he will
                win the battle for you. Still, keep in mind that without dupes
                he will start falling off around chapter 11-12 and he won't have
                enough damage to carry anymore, but you can still use him for
                the Taunt.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="demonic-fragment-hoaqin"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>DPS</td>
              <td>
                The best purple damage dealer who can deal stupid amounts of
                damage. His only issue is that he's melee so you need to use him
                with a tank, to take some heat off him or he will die before
                doing anything.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="assassin-khun-kiseia"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>DPS</td>
              <td>
                Another great damage dealer, but she's more of an Assassin who
                teleports to the backline to disable and kill a target. Deals
                really well against enemy teams that try to hide some important
                character in the backline.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="strategist-khun-aguero"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>Support</td>
              <td>
                Another supporter who is focused on CC/Debuffs. His Special move
                can win the battle instantly if it catches the enemy team in a
                clump, but he has the issue of slow mana regen - so it takes him
                time to use this ability.
              </td>
            </tr>
          </tbody>
        </Table>
        <h5>Other factions</h5>
        <p>
          Here's a few characters from other factions that you will want to
          invest into for sure:
        </p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Character</th>
              <th>Role</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="child-endorsi"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>DPS</td>
              <td>
                The core member of the Green team who acts as a DPS - both in
                story and Bosses.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="one-eyed-wolf-blarode"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>DPS / Debuffer</td>
              <td>
                One of the best characters to use on bosses, but he actually
                works well in both Story and PVP too - his damage output is
                still decent there, but his ability to pull someone from the
                enemy backline to him is what allows him to shine there. The
                best counter to enemy Evan.
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <TOGCharacter
                    mode="icon"
                    slug="center-director-blanc"
                    enablePopover
                    showIcon
                  />
                </Card>
              </td>
              <td>Healer / Buffer</td>
              <td>
                A great healer / buffer hybrid who is the cornerstone of Blue
                teams.
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Building your first team" />
        <p>
          Now that you know what characters are worth using, it's time to build
          your perfect team! Keep in mind that once you reach Floor 16, for
          every Elite stage you will have to use two teams. Still, we suggest to
          focus on the main team first and only when you max (so +21) your main
          DPS, you should start working on the second team.
        </p>
        <h5>Best early progression team (Yellow)</h5>
        <div className="custom-team-showcase tog">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="flame-user-yihwa-yeon"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="healer">Healer</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="fast-ship-evan"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="black-march-bam"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="shielder">Stall tank</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="data-zahard"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="pink-knit-sweater-goseng-yeo"
                enablePopover
                showIcon
              />
            </Card>
          </div>
        </div>
        <p>Replacements:</p>
        <ul>
          <li>
            Use Vespa instead of Data Zahard once you reach the point in the
            story where you need to build a second and third team,
          </li>
          <li>Goseng Yeo can be replaced by anyone you want to be honest.</li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default TOGGuidesEarlyPage;

export const Head: React.FC = () => (
  <Seo
    title="Early team progression | Tower of God: New World | Prydwen Institute"
    description="A guide to help you build your early-game team!"
    game="tog"
  />
);
