import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  customClass?: string;
  firstControl: React.ReactNode;
}

export const SingleControlHeader: React.FC<IProps> = ({
  title,
  subtitle,
  customClass,
  firstControl
}) => {
  return (
    <div
      className={`section-header simple with-control ${
        customClass ? customClass : ''
      }`}
    >
      <h2 className="section-title">
        <FontAwesomeIcon icon={faSquare} width="18" />
        {title}
      </h2>
      {subtitle && <span className="additional-text">{subtitle}</span>}
      <div className="control">{firstControl}</div>
    </div>
  );
};
